import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Input = ({
  type = "text",
  disabled = false,
  error,
  alitgment,
  value,
  align,
  onChange,
  placeholder,
  prefix,
  suffix,
  icon,
  borderStyle = "border",
  size = "sm",
  defaultValue,
  ...props
}) => {
  const sizeClasses = {
    sm: "p-2 text-sm",
    md: "p-3 text-base",
    lg: "p-4 text-lg",
  };

  return (
    <div>
      <div
        className={classNames(
          " mt-1 items-center",
          borderStyle,
          sizeClasses[size],
          "rounded-md"
        )}
      >
        {icon && <img src={icon} alt="icon" className="mr-2 w-5 h-5" />}
        {suffix && <p className="text-[10px] m-1">{suffix}</p>}

        <input
          disabled={props.disabled}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={`flex-1 outline-none bg-transparent ${align}`}
          {...props}
        />
        {prefix && <p className="text-[10px] m-1">{prefix}</p>}
      </div>

      {error && <p className="text-[10px] m-1 text-rose-700">{error ?? ""}</p>}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  borderStyle: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  defaultValue: PropTypes.string,
};

export default Input;
