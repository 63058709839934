import React from "react";
import { Routes, Route } from "react-router-dom";

import MainLayout from "../layout/main/MainLayout";
import PosPage from "../pages/pos/PosPage";
import LoginPage from "../pages/auth/LoginPage";
import DashboardLayout from "../layout/main/DashboardLayout";
import CustomerList from "../pages/customers/CustomerList";
import productsList from "../pages/products/productsList";
import SalesList from "../pages/sales/SalesList";
import InvoiceDetails from "../pages/sales/InvoiceDetails";
import CustomerAdd from "../pages/customers/CustomerAdd";
import EmployeesList from "../pages/employees/EmployeesList";
import EmployeesAdd from "../pages/employees/EmployeesAdd";
import SimplifiedInvoicePage from "../pages/pos/SimplifiedInvoicePage";
import MobilePosLayout from "../layout/pos/MobilePosLayout";
import NotFoundPage from "../pages/NotFoundPage";
import CustomersPage from "../pages/pos/CustomersPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" Component={LoginPage} />

      <Route Component={MainLayout}>
        <Route path="/" Component={MobilePosLayout}>
          <Route path="/pos" Component={PosPage} />
          <Route path="/customers" Component={CustomersPage} />
        </Route>

        <Route path="/dashboard" Component={DashboardLayout}>
          <Route path="customers" Component={CustomerList} />
          <Route path="customers/add" Component={CustomerAdd} />

          <Route path="products" Component={productsList} />

          <Route path="sales" Component={SalesList} />
          <Route path="sales/:invoiceNumber" Component={InvoiceDetails} />

          <Route path="employees" Component={EmployeesList} />
          <Route path="employees/add" Component={EmployeesAdd} />
        </Route>
        <Route
          path="simplified-invoic/:invoiceNumber"
          Component={SimplifiedInvoicePage}
        />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
