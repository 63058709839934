import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContextProvider";

function MainLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useContext(AppContext);

  const checkUserAuth = () => {
    const storedUser = localStorage.getItem("user");

    console.log("ahmed hmed", storedUser);
    if (!storedUser === null) {
      // If no user in localStorage, remove from context and redirect to login
      setUser(null);
      navigate("/login");
    } else {
      // If user exists in localStorage but not in context, set it in context
      if (!user) {
        setUser(JSON.parse(storedUser));
      }
    }
  };

  useEffect(() => {
    checkUserAuth();
  }, [location, navigate, setUser, user]);

  // If no user, don't render the outlet
  if (!user) {
    // return null;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default MainLayout;
