

import "./App.css";
import AppContextProvider from "./contexts/AppContextProvider";


import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/routes";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <AppContextProvider>

      <Toaster reverseOrder={false} />
      <Router>
        <AppRoutes />
      </Router>

    </AppContextProvider>
  );
}

export default App;
