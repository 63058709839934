import React from "react";
import { UserPlus } from "lucide-react";
import useFetchData from "../../hooks/useFetchData";

function ProductsList() {
  const {
    data: products = [],
    error,
    isLoading,
    refetch,
  } = useFetchData("/products");

  if (error) {
    return (
      <div className="text-center py-4 text-red-500">
        حدث خطأ: {error.message}
      </div>
    );
  }

  return (
    <div className="space-y-4 bg-white p-4 rounded-lg">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">قائمة المنتجات</h1>
        {/* <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center">
          <UserPlus className="mr-2" size={20} />
          إضافة منتج جديد
        </button> */}
      </div>
      {isLoading ? (
        <div className="text-center py-4 text-gray-500">جاري التحميل...</div>
      ) : products.length === 0 ? (
        <div className="text-center py-4 text-gray-500">
          لا توجد نتائج مطابقة للبحث
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  الصوره
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  الاسم
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  الوصف
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  السعر
                </th>
                {/* <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  الإجراءات
                </th> */}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {products.map((product) => (
                <tr key={product.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-10 h-10 rounded-full"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {product.name}
                  </td>
                  <td className="px-6 py-4 ">{product.description}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {product.price} SAR
                  </td>
                  {/* <td className="flex py-4 gap-2 whitespace-nowrap">
                    <button className="bg-green-100 text-green-600 hover:bg-green-200 font-semibold py-1 px-3 rounded-md text-sm">
                      عرض
                    </button>
                    <button className="bg-blue-100 text-blue-600 hover:bg-blue-200 font-semibold py-1 px-3 rounded-md text-sm mr-2">
                      تعديل
                    </button>
                    <button className="bg-red-100 text-red-600 hover:bg-red-200 font-semibold py-1 px-3 rounded-md text-sm">
                      حذف
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ProductsList;
