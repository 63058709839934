import React from "react";

const ActionButton = ({
  onClick,
  isLoading,
  text,
  loadingText,
  backgroundColor = "bg-blue-500",
  textColor = "text-white",
  icon,
  disabled,
  className,
}) => {
  const baseClasses = `flex items-center justify-center px-4 py-2 rounded transition-all duration-300 ${backgroundColor} ${textColor}`;
  const disabledClasses = "opacity-80 cursor-not-allowed";
  const enabledClasses = "hover:opacity-80 active:opacity-70";

  return (
    <button
      onClick={onClick}
      disabled={isLoading || disabled}
      className={`${baseClasses} ${
        isLoading || disabled ? disabledClasses : enabledClasses
      } ${className}`}
    >
      {isLoading ? (
        <>
          <span className="font-regular">
            {isLoading ? loadingText || "جاري التنفيذ..." : text || "تنفيذ"}
          </span>

          <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </>
      ) : (
        <>
          {icon && <span className="mr-2">{icon}</span>}
          <span className="font-regular">
            {isLoading ? loadingText || "جاري التنفيذ..." : text || "تنفيذ"}
          </span>
        </>
      )}
    </button>
  );
};

export default ActionButton;
