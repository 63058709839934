import React, { useContext } from "react";
import { AppContext } from "../../../contexts/AppContextProvider";
import useFetchData from "../../../hooks/useFetchData";
import CustomerListItem from "./CustomerListItem";

function CustomerListItems() {
  const { card, setCard } = useContext(AppContext);

  const {
    data: customers,
    error,
    isLoading,
    refetch,
  } = useFetchData("/customers");

  return (
    <div className="p-4 items-center max-h-[70vh] overflow-y-scroll  ">
      {customers.map((item, index) => (
        <CustomerListItem
          key={index}
          card={card}
          customer={item}
          isSelected={card.customer_id == item.id}
          onSelect={() => setCard({ ...card, customer_id: item.id })}
        />
      ))}
    </div>
  );
}

export default CustomerListItems;
