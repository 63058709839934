import React, { useState } from "react";
import { Search, UserPlus } from "lucide-react";
import useFetchData from "../../hooks/useFetchData";

const CustomerList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data: customers, isLoading, refetch } = useFetchData("/customers");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.trim() === "") {
      refetch();
    } else {
      const filteredCustomers = customers.filter(
        (customer) =>
          customer?.name?.toLowerCase()?.includes(term) ||
          customer?.email?.toLowerCase()?.includes(term) ||
          customer?.phone?.includes(term) ||
          customer?.address.toLowerCase().includes(term)
      );
      // setCustomers(filteredCustomers);
    }
  };

  return (
    <div className="space-y-4 bg-white  p-4 rounded-lg">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">قائمة العملاء</h1>
        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center">
          <UserPlus className="mr-2" size={20} />
          إضافة عميل جديد
        </button>
      </div>

      <div className="relative bg-white">
        <input
          type="text"
          placeholder="البحث عن عميل..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <Search
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          size={20}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                الاسم
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                البريد الإلكتروني
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                رقم الهاتف
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                المدينة
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                الإجراءات
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {customers.map((customer) => (
              <tr key={customer.id}>
                <td className="px-6 py-4 whitespace-nowrap">{customer.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {customer.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {customer.phone}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {customer.address}
                </td>
                <td className="flex py-4 gap-2 whitespace-nowrap">
                  {/* عرض */}
                  <button className="bg-green-100 text-green-600 hover:bg-green-200 font-semibold py-1 px-3 rounded-md text-sm">
                    عرض
                  </button>
                  <button className="bg-blue-100 text-blue-600 hover:bg-blue-200 font-semibold py-1 px-3 rounded-md text-sm mr-2">
                    تعديل
                  </button>
                  <button className="bg-red-100 text-red-600 hover:bg-red-200 font-semibold py-1 px-3 rounded-md text-sm">
                    حذف
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isLoading ? (
        <div className="text-center py-4 text-gray-500">جاري التحميل...</div>
      ) : (
        customers.length === 0 && (
          <div className="text-center py-4 text-gray-500">
            لا توجد نتائج مطابقة للبحث
          </div>
        )
      )}
    </div>
  );
};

export default CustomerList;
