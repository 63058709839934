import React from "react";
import { Printer } from "lucide-react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";

const InvoiceDetails = () => {
  // get id for url
  const { invoiceNumber } = useParams();

  const {
    data: invoiceData,
    error,
    isLoading,
    refetch,
  } = useFetchData(`/sales/${invoiceNumber}`);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-SA", {
      style: "currency",
      currency: "SAR",
    }).format(amount);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("ar-SD", options);
  };
  if (isLoading) {
    return (
      <div className="text-center bg-white h-screen items-center flex justify-center">
        جارى جلب البيانات...
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl mx-auto">
      <div className="flex justify-between items-center">
        <div>
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-800">فاتوره ضريبة</h1>
          </div>
          <div className="flex justify-between items-center mb-8">
            <div>
              <p className="text-gray-600">
                رقم الفاتورة: {`#${invoiceNumber}`}
              </p>
              <p className="text-gray-600">
                تاريخ الفاتورة: {formatDate(invoiceData?.created_at)}
              </p>
            </div>
          </div>
        </div>

        {invoiceData?.qrcode && (
          <QRCode className="h-32" value={invoiceData?.qrcode} />
        )}
      </div>

      <div className="grid grid-cols-1 gap-8 mb-8">
        <div>
          <h2 className="text-lg font-semibold mb-2">معلومات البائع</h2>
          <div className="grid grid-cols-4 gap-4 border p-2 rounded-md">
            <p className="font-bold">الاسم : مؤسسة لقمة جدو صبحي للحلويات</p>
            <p> العنوان : {`الرياض. ٣٢٢٩ سعيد بن عامر- حي النخيل١٢٣٩٥`}</p>
            <p>الرقم الضريبي: {`310534675200003 `}</p>
            <p>السجل التجارى: {`1009031161`}</p>
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-2">معلومات المشتري</h2>
          <div className="grid grid-cols-4 gap-4 border p-2 rounded-md">
            <div>
              <p>الاسم: {invoiceData?.customer?.name}</p>
            </div>
            <p> العنوان: {invoiceData?.customer?.address}</p>
            <p>الرقم الضريبي: {invoiceData?.customer?.vat_no}</p>
            <p>السجل التجارى: {invoiceData?.customer?.vat_no}</p>
          </div>
        </div>
      </div>

      <table className="w-full mb-8">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-right">المنتج</th>
            <th className="px-4 py-2 text-right">الكمية</th>
            <th className="px-4 py-2 text-right">سعر الوحدة</th>
            <th className="px-4 py-2 text-right">الإجمالي</th>
          </tr>
        </thead>
        <tbody>
          {invoiceData?.items &&
            invoiceData?.items.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="px-4 py-2">{item?.product?.name}</td>
                <td className="px-4 py-2">{item.quantity}</td>
                <td className="px-4 py-2">{formatCurrency(item.unit_price)}</td>
                <td className="px-4 py-2">{formatCurrency(item.subtotal)}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="flex justify-end">
        <div className="w-64">
          <div className="flex justify-between mb-2">
            <span>الإجمالي قبل الضريبة:</span>
            <span>{formatCurrency(invoiceData?.subtotal)}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span>ضريبة القيمة المضافة ({15}%):</span>
            <span>{formatCurrency(invoiceData?.vatAmount)}</span>
          </div>
          <div className="flex justify-between font-bold text-lg">
            <span>الإجمالي شامل الضريبة:</span>
            <span>{formatCurrency(invoiceData?.total)}</span>
          </div>
        </div>
      </div>

      <div className="mt-8 text-sm text-gray-600">
        <p>
          تم إصدار هذه الفاتورة الإلكترونية وفقًا لأنظمة هيئة الزكاة والضريبة
          والجمارك في المملكة العربية السعودية.
        </p>
      </div>
    </div>
  );
};

export default InvoiceDetails;
