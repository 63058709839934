import React from "react";
import Input from "../../components/common/Input";
import ActionButton from "../../components/common/ActionButton";
import GeneralServices from "../../services/GeneralServices";
import { showSuccessToast } from "../../utils/toastUtils";

function CustomerAdd() {
  const [customer, setCustomer] = React.useState({
    name: "",
    address: "",
    vat_no: "",
    phone: "",
  });

  const [validations, setValidations] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const service = new GeneralServices();

  const handleAddCustomer = async () => {
    setValidations([]);
    setIsLoading(true);
    try {
      const { data } = await service.post("/customers", customer);
      showSuccessToast("تمت العملية بنجاح");
      setCustomer({
        name: "",
        address: "",
        vat_no: "",
        phone: "",
        email: "",
      });
    } catch (error) {
      console.log("error", error);

      if (error.state == 422) {
        setValidations(error.validationData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg">
      <h1>اضافة عميل </h1>
      <hr className="border-gray-300 border-dashed my-2" />

      <div className="grid grid-cols-1 gap-4">
        <Input
          onChange={(e) => setCustomer({ ...customer, name: e.target.value })}
          type="text"
          value={customer.name}
          placeholder="اسم العميل"
          error={validations?.name?.[0]}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
          type="email"
          value={customer.email}
          placeholder="البريد الالكتروني"
          error={validations?.email?.[0]}
        />
        <Input
          onChange={(e) =>
            setCustomer({ ...customer, address: e.target.value })
          }
          type="text"
          value={customer.address}
          placeholder="العنوان"
          error={validations?.address?.[0]}
        />
        <Input
          onChange={(e) => setCustomer({ ...customer, vat_no: e.target.value })}
          type="text"
          value={customer.vat_no}
          placeholder="الرقم الضريبي"
          error={validations?.vat_no?.[0]}
        />
        <Input
          onChange={(e) => setCustomer({ ...customer, phone: e.target.value })}
          type="text"
          value={customer.phone}
          placeholder="الهاتف"
          error={validations?.phone?.[0]}
        />
      </div>

      <div className="mt-4">
        <ActionButton
          text="حفظ"
          isLoading={isLoading}
          onClick={handleAddCustomer}
          loadingText="جاري اضافة عميل "
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        />
      </div>
    </div>
  );
}

export default CustomerAdd;
