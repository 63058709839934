import React, { useContext, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Home, Users, ShoppingCart, Menu } from "lucide-react";
import { AppContext } from "../../contexts/AppContextProvider";

function MobilePosLayout() {
  const { user, setUser } = useContext(AppContext);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("user");

    if (userData === null) {
      setUser(null);
      navigate("/login");
    }
  }, [pathname]);

  return (
    <div>
      <Outlet />
      <div className="h-24"></div>
      <BottomNavigation />
    </div>
  );
}

const BottomNavigation = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "text-blue-400" : "text-gray-400";
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 h-20 bg-white border-t border-gray-200 shadow-lg z-100">
      <div className="flex justify-around items-center h-full px-6 max-w-md mx-auto">
        <NavItem
          to="/pos"
          icon={<ShoppingCart size={24} />}
          label="نقاط البيع"
          isActive={isActive("/pos")}
        />
        <NavItem
          to="/customers"
          icon={<Users size={24} />}
          label="العملاء"
          isActive={isActive("/customers")}
        />
        <NavItem
          to="/dashboard"
          icon={<Home size={24} />}
          label="لوحة التحكم"
          isActive={isActive("/dashboard")}
        />
        <NavItem
          to="/menu"
          icon={<Menu size={24} />}
          label="القائمة"
          isActive={isActive("/menu")}
        />
      </div>
    </div>
  );
};

const NavItem = ({ to, icon, label, isActive }) => (
  <Link
    to={to}
    className={`flex flex-col items-center space-y-1 group ${isActive}`}
  >
    <div
      className={`p-2 rounded-full transition-colors duration-300 ${
        isActive.includes("blue") ? "bg-blue-100" : "group-hover:bg-gray-100"
      }`}
    >
      {icon}
    </div>
    <span className="text-xs font-medium">{label}</span>
  </Link>
);

export default MobilePosLayout;
