import React from "react";
import useFetchData from "../../hooks/useFetchData";
import CustomerListItem from "./components/CustomerListItem";
import BottomSheet from "../../components/common/BottomSheet";
import Loader from "../../components/common/Loader";

function CustomersPage() {
  const {
    data: customers,
    error,
    isLoading,
    refetch,
  } = useFetchData("/customers");

  if (isLoading) <Loader />;

  return (
    <div className="bg-white shadow-sm">
      <div className="w-full flex text-white pb-2 bg-teal-500 border-b border-dashed items-center justify-center">
        <p className="text-lg">بيانات العملاء</p>
      </div>
      <BottomSheet></BottomSheet>
      {customers.map((item, index) => (
        <CustomerListItem
          customer={item}
          onSelect={() => refetch()}
          key={index}
        />
      ))}
    </div>
  );
}

export default CustomersPage;
