import React, { createContext, useState, useEffect } from "react";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [title, setTitle] = useState("");

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [card, setCard] = useState({
    products: [],
    paymentMethod: null,
    customer_id: null,
    total: 0,
    payment: 0,
  });

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <AppContext.Provider
      value={{ user, setUser, card, setCard, title, setTitle }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
