import React from "react";
import { UserPlus } from "lucide-react";
import { Link } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";

function EmployeesList() {
  const { data: employees, isLoading } = useFetchData("/employees");

  return (
    <div className="space-y-4 bg-white p-4 rounded-lg">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">قائمة الموظفين</h1>

        {/* اضافة موظف */}
        <Link
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 gap-2 px-4 rounded flex items-center"
          to="/dashboard/employees/add"
        >
          <UserPlus className="mr-2" size={20} />
          اضافة موظف
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                الاسم
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                الوظيفة
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                الموبايل
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                العنوان
              </th>
            </tr>
          </thead>
          <tbody>
            {employees?.map((employee) => (
              <tr key={employee._id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {employee.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {employee.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <p
                    className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${
                      employee.status === "active"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {employee.status === "active" ? "مفعل" : "غير مفعل"}
                  </p>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <p
                    className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${
                      employee.role === "active"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {employee.role === "admin" ? "مدير" : "موظف"}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isLoading ? (
        <div className="text-center py-4 text-gray-500">جارى التحميل...</div>
      ) : (
        employees.length === 0 && (
          <div className="text-center py-4 text-gray-500">
            لا توجد بيانات فى قاعده البيانات
          </div>
        )
      )}
    </div>
  );
}

export default EmployeesList;
