import React from "react";
import { ArrowLeft, Check } from "lucide-react";

const CustomerListItem = ({ customer, isSelected, onSelect }) => {
  return (
    <div
      className={`p-4 border-b flex justify-between items-center cursor-pointer transition-colors duration-200 ${
        isSelected ? "bg-blue-50 hover:bg-blue-100" : "hover:bg-gray-50"
      }`}
      onClick={() => onSelect(customer)}
    >
      <div className="flex items-center gap-3">
        <div className="relative">
          <img
            src={customer.image || "https://via.placeholder.com/40"}
            className="w-12 h-12 rounded-full object-cover border-2 border-gray-200"
            alt={customer.name}
          />
          {isSelected && (
            <div className="absolute -bottom-1 -right-1 bg-green-500 rounded-full p-1">
              <Check className="w-3 h-3 text-white" />
            </div>
          )}
        </div>
        <div>
          <h3 className="text-sm font-semibold text-gray-800">
            {customer.name}
          </h3>
          <p className="text-xs text-gray-500">{customer.phone}</p>
        </div>
      </div>
      <ArrowLeft
        className={`w-5 h-5 ${isSelected ? "text-blue-500" : "text-gray-400"}`}
      />
    </div>
  );
};

export default CustomerListItem;
