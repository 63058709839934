import React from "react";
import GeneralServices from "../services/GeneralServices";
import { showSuccessToast } from "../utils/toastUtils";

const useSubmitData = (initialState, endpoint) => {
  const [data, setData] = React.useState(initialState);
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const service = new GeneralServices();

  const submit = async () => {
    setErrors([]);
    setIsLoading(true);
    try {
      const response = await service.post(endpoint, data);
      showSuccessToast("تمت العملية بنجاح");
      setData(initialState);
      return response.data;
    } catch (error) {
      console.log("error", error);
      if (error.state === 422) {
        setErrors(error.validationData);
      } else {
        setErrors(error.message);
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    setData,
    errors,
    isLoading,
    submit,
  };
};

export default useSubmitData;
