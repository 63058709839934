import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import {
  Home,
  Users,
  Package,
  ShoppingCart,
  UserCircle,
  LogOut,
  Settings,
  Menu,
  X,
  List,
  UserPlus,
  Edit,
  PoundSterling,
} from "lucide-react";

const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const menuItems = [
    { name: "الرئيسية", icon: Home, path: "/dashboard" },
    {
      name: "العملاء",
      icon: Users,
      path: "/dashboard/customers",
      subItems: [
        { name: "عرض العملاء", icon: List, path: "/dashboard/customers" },
        {
          name: "إضافة عميل",
          icon: UserPlus,
          path: "/dashboard/customers/add",
        },
      ],
    },
    { name: "المنتجات", icon: Package, path: "/dashboard/products" },
    { name: "نقاط البيع", icon: PoundSterling, path: "/pos" },
    {
      name: "المبيعات",
      icon: ShoppingCart,
      path: "/dashboard/sales",
      subItem: [
        { name: "عرض المبيعات", icon: List, path: "/dashboard/sales/list" },
        { name: "إضافة مبيعة", icon: Edit, path: "/dashboard/sales/add" },
      ],
    },
    { name: "الموظفين", icon: UserCircle, path: "/dashboard/employees" },
    { name: "الإعدادات", icon: Settings, path: "/dashboard/settings" },
  ];

  const handleLogout = async () => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("user");
    window.location.href = "/login";
  };
  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`bg-white shadow-md transition-all duration-300 ease-in-out ${
          isSidebarOpen ? "w-64" : "w-20"
        }`}
      >
        <div className="flex items-center justify-between p-4">
          <h2
            className={`text-xl font-bold text-gray-800 ${
              isSidebarOpen ? "" : "hidden"
            }`}
          >
            لوحة التحكم
          </h2>
          <button
            onClick={toggleSidebar}
            className="text-gray-500 hover:text-gray-700"
          >
            {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        <nav className="mt-8">
          <ul>
            {menuItems.map((item, index) => (
              <li key={index}>
                <Link
                  to={!item.subItems ? item.path : null}
                  className="flex items-center px-4 py-3 text-gray-700 hover:bg-blue-100 hover:text-blue-600"
                >
                  <item.icon size={20} className="ml-2" />
                  <span className={isSidebarOpen ? "" : "hidden"}>
                    {item.name}
                  </span>
                </Link>
                {item.subItems && isSidebarOpen && (
                  <ul className="mr-6 mt-2">
                    {item.subItems.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={subItem.path}
                          className="flex items-center px-4 py-2 text-sm text-gray-600 hover:bg-blue-50 hover:text-blue-600"
                        >
                          <subItem.icon size={16} className="ml-2" />
                          <span>{subItem.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="absolute bottom-0 w-full p-4">
          <button
            onClick={handleLogout}
            className="flex items-center px-4 py-3 text-red-600 hover:bg-red-100"
          >
            <LogOut size={20} className="ml-2" />
            <span className={isSidebarOpen ? "" : "hidden"}>تسجيل خروج</span>
          </button>
        </div>
      </aside>

      {/* Main content */}
      <main className="flex-1 overflow-x-hidden overflow-y-auto">
        <div className="container mx-auto px-6 py-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default DashboardLayout;
