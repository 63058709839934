import React from "react";
import useFetchData from "../../hooks/useFetchData";
import { Link, useParams } from "react-router-dom";
import QRCode from "react-qr-code";

function SimplifiedInvoicePage() {
  const { invoiceNumber } = useParams();
  const {
    data: invoiceData,
    error,
    isLoading,
  } = useFetchData(`/sales/${invoiceNumber}`);

  if (isLoading) {
    return <div className="text-center py-4">جاري التحميل...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-4 text-red-500">
        حدث خطأ: {error.message}
      </div>
    );
  }

  if (!invoiceData || !invoiceData.items) {
    return (
      <div className="text-center py-4">لا توجد بيانات متاحة للفاتورة.</div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen  items-center justify-center px-[5%] md:px-[20%] py-8">
      <div className="bg-white w-full justify-center p-4 rounded-md shadow-md">
        <div className="text-center mb-12 py-10">
          <h1 className="text-3xl font-bold text-gray-800 mb-8">
            فاتورة ضريبية مبسطة
          </h1>
          <h2 className="text-lg text-gray-600">
            رقم الفاتورة #{invoiceNumber}
          </h2>
          <p className="text-gray-600">{"مؤسسة لقمة جدو صبحي للحلويات"}</p>
          <p className="text-gray-600">
            <p>رقم تسجيل ضريبة القيمة المضافة</p>
            <p>310534675200003 </p>
          </p>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                  المنتجات
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                  الكمية
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                  السعر
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                  المجموع
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {invoiceData.items.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {item?.product?.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item?.quantity}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item?.unit_price} SAR
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {(item?.quantity * item?.unit_price).toFixed(2)} SAR
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-8 text-right">
          <div className="flex justify-between items-center border-b border-dashed pb-1">
            <p className="text-lg font-semibold">المجموع</p>
            <p className="text-lg font-semibold">{invoiceData.subtotal} SAR</p>
          </div>
          <div className="flex justify-between items-center border-b border-dashed pb-1">
            <p className="text-lg font-semibold">قيمه الضريبه (15%)</p>
            <p className="text-lg font-semibold">{invoiceData.vatAmount} SAR</p>
          </div>
          <div className="flex justify-between items-center border-b border-dashed pb-1">
            <p className="text-lg font-semibold">المجموع</p>
            <p className="text-lg font-semibold">{invoiceData.total} SAR</p>
          </div>

          <div className="flex justify-center items-center my-8 ">
            <p>اغلاق الفاتوره</p>
          </div>

          <div className="flex justify-center items-center">
            {invoiceData?.qrcode && (
              <QRCode className="h-32" value={invoiceData?.qrcode} />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-4 mt-8 items-center">
        <Link to="/pos">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          >
            نقاط البيع
          </button>
        </Link>
        <Link to="/dashboard">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          >
            لوحه التحكم
          </button>
        </Link>
      </div>
    </div>
  );
}

export default SimplifiedInvoicePage;
