import React, { useContext, useState } from "react";
import { Eye, EyeOff, Lock, Mail } from "lucide-react";
import GeneralServices from "../../services/GeneralServices";
import { AppContext } from "../../contexts/AppContextProvider";

const LoginPage = () => {
  const { setUser } = useContext(AppContext);

  const [showPassword, setShowPassword] = useState(false);

  const [user, setUserData] = useState({
    email: "",
    password: "",
  });

  const [validation, setValidation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const service = new GeneralServices();
  const handleLogin = async (e) => {
    setValidation([]);
    setIsLoading(true);
    e.preventDefault();
    try {
      const data = await service.post("login", user);

      if (data.success) {
        localStorage.setItem("user", JSON.stringify(data));
        setUser(data);
        window.location.href = "/pos";
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.state === 422) {
        setValidation(error.validationData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-teal-500 to-purple-600 p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md">
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800 font-['Baloo_Bhaijaan_2']">
          مرحبا بعودتك
        </h2>
        <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
          <div className="relative">
            <label
              htmlFor="email"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              البريد الالكتروني
            </label>
            <div className="flex items-center">
              <Mail className="w-5 h-5 text-gray-400 absolute left-3 " />
              <input
                onChange={(e) =>
                  setUserData({ ...user, email: e.target.value })
                }
                type="email"
                id="email"
                className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500"
                value={user.email}
                placeholder="you@example.com"
              />
            </div>
            <span className="text-red-500 text-sm">
              {validation?.email?.[0]}
            </span>
          </div>

          <div className="relative items-center">
            <label
              htmlFor="password"
              value={user.password}
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              كلمه السر
            </label>
            <div className="flex items-center ">
              <Lock className="w-5 h-5 text-gray-400 absolute left-3 " />
              <input
                onChange={(e) =>
                  setUserData({ ...user, password: e.target.value })
                }
                type={showPassword ? "text" : "password"}
                id="password"
                className="w-full pl-10 pr-10 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500"
                placeholder="••••••••"
              />
              <button
                type="button"
                className="absolute right-3 "
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOff className="w-5 h-5 text-gray-400" />
                ) : (
                  <Eye className="w-5 h-5 text-gray-400" />
                )}
              </button>
            </div>

            <span className="text-red-500 text-sm">
              {validation?.password?.[0]}
            </span>
          </div>
          {/* <div className="flex items-center justify-between">
           
            <div className="text-sm">
              <a
                href="#"
                className="font-medium text-teal-600 hover:text-teal-500"
              >
                Forgot your password?
              </a>
            </div>
          </div> */}
          <div>
            <button
              type="submit"
              onClick={handleLogin}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
            >
              دخول
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
