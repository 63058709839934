import React, { useState } from 'react';
import './Switch.css'; // Import the CSS file for styling

function Switch({ checked, onChange }) {
    const [isChecked, setIsChecked] = useState(true);

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <label className="switch">
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span className="slider round"></span>
        </label>
    );
}

export default Switch;
