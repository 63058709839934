import React, { useEffect } from "react";
import Input from "../../components/common/Input";
import Switch from "../../components/common/Switch";
import ActionButton from "../../components/common/ActionButton";
import useSubmitData from "../../hooks/useSubmitData";
import { useNavigate } from "react-router-dom";

function EmployeesAdd() {
  const initialState = {
    name: "",
    email: "",
    role: "admin",
    password: "",
  };

  const {
    data: employee,
    setData: setEmployee,
    errors,
    isLoading,
    submit,
  } = useSubmitData(initialState, "/employees");

  const navigate = useNavigate();

  const handleAddEmployee = async () => {
    try {
      await submit();
      navigate("/dashboard/employees");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg">
      <h1 className="text-2xl font-bold text-gray-800">اضافة موظف</h1>
      <hr className="border-dashed my-1" />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Input
          onChange={(e) => setEmployee({ ...employee, name: e.target.value })}
          label="الاسم"
          placeholder="ادخل الاسم"
          type="text"
          error={errors?.name?.[0]}
        />
        <Input
          onChange={(e) => setEmployee({ ...employee, email: e.target.value })}
          label="الاسم"
          placeholder="ادخل البريد"
          type="email"
          error={errors?.email?.[0]}
        />
        <Input
          onChange={(e) =>
            setEmployee({ ...employee, password: e.target.value })
          }
          label="الاسم"
          placeholder="ادخل كلمه السر"
          type="password"
          error={errors?.password?.[0]}
        />
        <div>
          <div className="flex items-center gap-2">
            <Switch
              checked={employee.role === "admin"}
              onChange={(e) =>
                setEmployee({
                  ...employee,
                  role: e.target.checked ? "admin" : "user",
                })
              }
              label="الاسم"
            />
            <p>ادمن</p>
          </div>
          <p className="text-red-500">{errors?.role?.[0]}</p>
        </div>
      </div>

      <div className="py-4">
        <ActionButton
          text="حفظ"
          loadingText={"جاري التنفيذ..."}
          loading={isLoading}
          onClick={handleAddEmployee}
        />
      </div>
    </div>
  );
}

export default EmployeesAdd;
