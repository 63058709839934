import React, { useContext, useEffect, useState } from "react";

import {
  Menu,
  PlusCircle,
  ArrowLeft,
  ShoppingBag,
  Building2,
  UserCircle,
} from "lucide-react";
import GeneralServices from "../../services/GeneralServices";
import { AppContext } from "../../contexts/AppContextProvider";
import useFetchData from "../../hooks/useFetchData";
import Loader from "../../components/common/Loader";
import BottomSheet from "../../components/common/BottomSheet";
import { useNavigate } from "react-router-dom";
import CustomerListItems from "./components/CustomerListItems";
import Input from "../../components/common/Input";
import ActionButton from "../../components/common/ActionButton";

function PosPage() {
  const { card, setCard } = useContext(AppContext);
  const [validation, setValidation] = useState([]);

  const addItem = (item) => {
    const existingItem = card.products.find((p) => p.id === item.id);
    if (existingItem) {
      setCard({
        products: card.products.map((p) =>
          p.id === item.id ? { ...p, qty: p.qty + 1 } : p
        ),
      });
    } else {
      setCard({
        products: [...card.products, { ...item, qty: 1 }],
      });
    }
  };

  // decrement qty
  const decrementQty = (item) => {
    const existingItem = card.products.find((p) => p.id === item.id);

    if (existingItem && existingItem.qty > 1) {
      setCard({
        products: card.products.map((p) =>
          p.id === item.id ? { ...p, qty: p.qty - 1 } : p
        ),
      });
    } else if (existingItem) {
      setCard({
        products: card.products.filter((p) => p.id !== item.id),
      });
    }
  };

  const [activeCategory, setActiveCategory] = useState(null);

  const [isOpen, setIsOpen] = useState({
    customers: false,
    payments: false,
  });

  const onClose = (model) => {
    if (model === "customers") {
      setIsOpen({
        ...isOpen,
        customers: !isOpen.customers,
      });
    }
    if (model === "payments") {
      setIsOpen({
        ...isOpen,
        payments: !isOpen.payments,
      });
    }
  };

  const navigate = useNavigate();

  const { data: products, isLoading: productsIsLoading } =
    useFetchData("/products");

  const services = new GeneralServices();

  const dispatchOrder = async () => {
    try {
      const response = await services.post("/orders", card);
      setCard({
        products: [],
        payment: 0,
        total: 0,
        customer_id: null,
      });
      navigate(`/simplified-invoic/${response.id}`);
    } catch (error) {
      console.error(error);
      if (error.state === 401) {
        window.location.href = "/login";
      } else if (error.state === 422) {
        setValidation(error.validationData);
      }
    }
  };

  useEffect(() => {
    setCard({
      ...card,
      total: card.products.reduce(
        (total, item) => total + item.price * item.qty,
        0
      ),
    });
  }, [card.products]);

  return (
    <>
      <BottomSheet
        isOpen={isOpen.customers}
        onClose={() => onClose("customers")}
        title={"العملاء"}
      >
        <CustomerListItems />
      </BottomSheet>
      <BottomSheet
        isOpen={isOpen.payments}
        onClose={() => onClose("payments")}
        title={"الدفعيات"}
      >
        <div className="p-4">
          <div>
            <Input
              onChange={(e) => {
                setCard({
                  ...card,
                  payment: e.target.value,
                });
              }}
              type="number"
              value={card.payment}
              error={validation?.payment?.[0]}
              placeholder="ادخل المبلغ"
            />
          </div>
          <br />
          <ActionButton
            className={"w-full bg-teal-500"}
            text="تاكيد"
            onClick={dispatchOrder}
          />
        </div>
      </BottomSheet>
      <div className="bg-white h-screen flex flex-col">
        <div>
          <header className="p-4 flex justify-between items-center border-b">
            <Menu className="w-6 h-6" />
            <h1 className="text-xl font-semibold">المبيعات</h1>
            {card.customer_id ? (
              <UserCircle
                className="w-6 cursor-pointer select-none h-6 text-teal-500"
                onClick={() => setIsOpen("customers")}
              />
            ) : (
              <UserCircle
                className="w-6 cursor-pointer select-none h-6 text-teal-500"
                onClick={() => onClose("customers")}
              />
            )}
          </header>
          <Categories
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />

          <div className="p-4 flex justify-between items-center">
            <div className="flex gap-3">
              <div className="flex gap-2 items-center">
                <Building2 className="w-6 h-6 text-gray-400" />
                <span className="text-gray-400">
                  {" "}
                  <span className="text-teal-500 font-bold">
                    {card.total}
                  </span>{" "}
                  المجموع
                </span>
              </div>
              <span className="text-gray-400">|</span>
              <div className="flex gap-2 items-center">
                <ShoppingBag className="w-6 h-6 text-gray-400" />
                <div className="bg-teal-500 p-1 rounded-full text-white w-[20px] h-[20px] items-center flex justify-center font-bold">
                  <span className="text-white">{card?.products.length} </span>
                </div>
                <span className="text-gray-400">المنتجات فى السله</span>
              </div>
            </div>
          </div>
        </div>

        {productsIsLoading && <Loader />}
        <Products
          products={products}
          card={card.products}
          addItem={addItem}
          decrementQty={decrementQty}
        />

        <footer className="p-4 bg-teal-500 text-white select-none cursor-pointer flex z-40 fixed bottom-20 w-full justify-center items-center">
          <div
            onClick={() => onClose("payments")}
            className="text-2xl  flex items-center gap-4"
          >
            <div>انها الطلب</div>
            <ArrowLeft />
          </div>
        </footer>
      </div>
    </>
  );
}

const Categories = ({ activeCategory, setActiveCategory }) => {
  const [categories, setCategories] = useState([]);

  const services = new GeneralServices();

  const fetchCategories = async () => {
    try {
      const response = await services.get("/categories");
      setCategories(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []); // eslint-disable-line

  return (
    <nav className="p-4 flex justify-between items-center border-b">
      <div className="flex gap-3 overflow-x-scroll scroll-m-0 pb-2">
        <span
          onClick={() => setActiveCategory(null)}
          className={`text-gray-400 cursor-pointer  px-2 rounded-full flex items-center ${
            !activeCategory ? "bg-teal-500 text-white" : "border"
          }`}
          style={{ whiteSpace: "nowrap" }}
        >
          الكل
        </span>
        {categories.map((category, index) => (
          <span
            onClick={() => setActiveCategory(category.id)}
            key={category.id}
            className={`text-gray-400 cursor-pointer  px-2 rounded-full flex items-center ${
              activeCategory === category.id
                ? "bg-teal-500 text-white"
                : "border"
            }`}
            style={{ whiteSpace: "nowrap" }}
          >
            {category.name}
          </span>
        ))}
      </div>
    </nav>
  );
};

const Products = ({ products, addItem, card, decrementQty }) => {
  const getQtyFromCart = (itemId) => {
    const productInCart = card.find((product) => product.id === itemId);
    return productInCart ? productInCart.qty : 0;
  };

  return (
    <div className="p-4 grid grid-cols-2 lg:grid-cols-6 md:grid-cols-4 overflow-y-scroll no-scrollbar gap-4 h-[70%]">
      {products.map((item, index) => (
        <div
          key={index}
          className="relative bg-gray-200 h-40 lg:h-56 md:h-48 rounded-lg overflow-hidden cursor-pointer"
          onClick={() => (getQtyFromCart(item.id) > 0 ? null : addItem(item))}
        >
          <img
            onClick={() => addItem(item)}
            src={item.image}
            alt={item.name}
            className="w-full h-32 lg:h-48"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
            <div className="font-semibold">{item.name}</div>
            <div>
              <span> {item.price ?? 0}</span>
              <span> ريال</span>
            </div>
          </div>

          {card.filter((selectedItem) => selectedItem.name === item.name)
            .length > 0 && (
            <div className=" top-0 w-full py-2 px-2 absolute flex gap-4 justify-between bg-black bg-opacity-65 select-none">
              <div
                onClick={() => addItem(item)}
                className=" w-5 h-5 flex items-center justify-center text-white bg-green-500 rounded-full"
              >
                +
              </div>
              <div className="    w-5 h-5 flex items-center justify-center text-white  rounded-full">
                {getQtyFromCart(item.id)}
              </div>
              <div
                onClick={() => decrementQty(item)}
                className="    w-5 h-5 flex items-center justify-center text-white bg-red-500 rounded-full"
              >
                -
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default PosPage;
